import React, { useState } from 'react'
import { Popup, Button, Icon, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { logout } from "store/actions/app";

const DEFEAULT_ICON = 'https://lh3.googleusercontent.com/d/1QnlXcMDJFAiEw1De8e88A7AjkicGFLX6'

const getAvatar = (user) => {
   if (user.photo && user.photo.length > 0) {
      return user.photo;
   } else {
      const name = user?.entity?.name ?? user.username
      return `https://ui-avatars.com/api/?name=${name}`
   }
}

const HeaderRouter = (props) => {
   const { user } = props;
   const [modalVisible, setModalVisible] = useState(false)

   let userName = user?.entity?.name
   let routes = [];
   const { village } = user;

   if (user.role === 'ADMIN') {
      routes = [{
         title: 'Casas',
         to: '/casas'
      }, {
         title: 'Visitas',
         to: '/visitas'
      }, {
         title: 'Guardias',
         to: '/guardias',
      }, {
         title: 'Mensajes',
         to: '/mensajes',
      }, {
         title: 'Eventos',
         to: '/eventos',
      }, {
         title: 'Servicios',
         to: '/servicios_publicos',
      }, {
         title: 'Cuotas',
         to: '/cuotas',
      }, {
         title: 'Reportes',
         to: '/reportes'
      }, {
         title: 'Pagos',
         to: '/pagos'
      }, {
         title: 'Configuración',
         to: '/configuracion'
      }]

      if (village?.is_module_payment_active === false) {
         routes.splice(5, 1);
      }

   } else if (user.role === 'SECURITY_UNIT') {
      routes = [{
         title: 'Visitas',
         to: '/visitas',
      }, {
         title: 'Casas',
         to: '/casas'
      }]
   } else if (user.role === 'SUPER_ADMIN') {
      userName = user?.username
   }

   const version = process.env.REACT_APP_VERSION;

   return (
      <div className="HeaderRouter">

         <div className="HeaderLeft" onClick={() => {
            props.history.push('/')

         }}>
            <h1>
               <Image avatar src={user?.village?.logo ?? DEFEAULT_ICON} />

               <span >Residencial</span>
            </h1>
            <div>
               {user?.village?.name}
            </div>
            <div className="HeaderVersion">V{version}</div>
         </div>
         <div className="HeaderRight">
            <ul className="HeaderRouterNavBar">
               {routes.map(route => <li key={route.to}>
                  <Link to={route.to}>{route.title}</Link>
               </li>)}
            </ul>
            <Popup
               onOpen={() => setModalVisible(true)}
               onClose={() => setModalVisible(false)}
               pinned
               on='click'
               trigger={<div className={`Header_username ${modalVisible && `Header_username_active`}`}>
                  <Image src={getAvatar(user)} avatar />
                  <span>{userName}</span>
                  <Icon name="angle down" />
               </div>}
               content={<div className="Header_PopUp">
                  <div >
                     <Image className="Header_PopUp_Image" src={getAvatar(user)} avatar />
                  </div>
                  <div className="Header_PopUp_Name">
                     {userName}
                  </div>
                  <div className="Header_PopUp_Email">
                     {user.role}
                  </div>
                  <Button
                     className="Header_PopUp_Button"
                     basic
                     icon
                     labelPosition='left'
                     onClick={() => { props.logout() }}>
                     <Icon name='sign-out' />
                     Cerrar sesión
                  </Button>
               </div>}
               position='bottom right'
            />
         </div>


      </div>
   )
}

const mapDispatchToProps = (dispatch) => ({
   logout: () => dispatch(logout())
});

const mapStateToProps = (state) => ({
   user: state.app.user
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderRouter);