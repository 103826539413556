const dayjs = require('dayjs');
const relativeTime = require("dayjs/plugin/relativeTime");
const calendar = require("dayjs/plugin/calendar");
const isoWeek = require("dayjs/plugin/isoWeek");

require('dayjs/locale/es')
dayjs.locale('es')
dayjs.extend(relativeTime);
dayjs.extend(calendar);
dayjs.extend(isoWeek);

const capitalize = (s) => {
   if (typeof s !== 'string') return ''
   return s.charAt(0).toUpperCase() + s.slice(1)
}

class CDate {
  constructor(...args) {
    this.date = dayjs(...args);
  }

  format(format, captilizaed) {
    const dateString = this.date.format(format);
    if (captilizaed) {
      return capitalize(dateString);
    } else {
      return dateString;
    }
  }

  from(date, withoutSuffix) {
    return this.date.from(date, withoutSuffix);
  }

  fromNow(withoutSuffix) {
    return this.date.fromNow(withoutSuffix);
  }

  /**
   * Formats the date using a calendar-specific representation.
   * @param {String} prop1 - The property to format the calendar. Should be one of: 'sameDay', 'nextDay', 'nextWeek', 'lastDay', 'lastWeek', 'sameElse'.
   * @param {Object} overwriteOptions - An optional object to overwrite the default formatting options.
   * @returns {String} - The formatted calendar representation of the date.
   */
  calendar(prop1, overwriteOptions) {
    const options = {
      sameDay: "[Hoy a las] HH:mm",
      nextDay: "[Mañana a las] HH:mm",
      nextWeek: "dddd [a las] HH:mm",
      lastDay: "[Ayer a las] HH:mm",
      lastWeek: "[El] dddd [pasado a las] HH:mm",
      sameElse: "MMM DD, YYYY [a las] HH:mm",
    };
    return this.date.calendar(prop1, {
      ...options,
      ...overwriteOptions,
    });
  }

  /**
   * Returns true or false if the given date is after the current date
   * Param 'unit' can be used to compare just the unit of time ('day', 'month', 'year)
   * @example
   * dateFormatter('01-02-2020').isAfter('01-01-2020') = true
   * dateFormatter('01-02-2019').isAfter('01-01-2020') = false
   * dateFormatter('02-02-2019').isAfter('01-01-2020', 'month') = true //compares month and year
   * @param {String} date
   * @param {String} unit
   * @returns {Boolean}
   */
  isAfter(date, number) {
    const dateToCompare = date instanceof CDate ? date.date : date;
    return this.date.isAfter(dateToCompare, number);
  }

  /**
   * Checks if the current date is before the provided date.
   * @param {DateFormatter|Date|String} date - The date to compare.
   * @param {String} unit - The unit of time to consider for the comparison ('day', 'month', 'year').
   * @returns {Boolean} - True if the current date is before the provided date, otherwise false.
   */
  isBefore(date, number) {
    const dateToCompare = date instanceof CDate ? date.date : date;
    return this.date.isBefore(dateToCompare, number);
  }

  /**
   * Checks if the current date is the same as the provided date.
   * @param {DateFormatter|Date|String} date - The date to compare.
   * @param {String} unit - The unit of time to consider for the comparison ('day', 'month', 'year').
   * @returns {Boolean} - True if the current date is the same as the provided date, otherwise false.
   */
  isSame(date, number) {
    const dateToCompare = date instanceof CDate ? date.date : date;
    return this.date.isSame(dateToCompare, number);
  }

  toDateZero() {
    return new Date(this.date).setHours(0, 0, 0, 0);
  }
  toDate() {
    return new Date(this.date);
  }
  set(label, number) {
    this.date = this.date.set(label, number);
  }
  subtract(number, label) {
    return this.date.subtract(number, label);
  }
  add(number, label) {
    return this.date.add(number, label);
  }
  clone() {
    return new CDate(this.date.toDate());
  }
}
export default (...args) => new CDate(...args);