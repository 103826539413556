import { toast } from 'react-toastify';

export const showErrorMessage = (res) => {
   let message = res ? (res.error ? res.error : JSON.stringify(res.error)): {}

   message = typeof message != 'string' ? 'Ha ocurrido un error. Favor de contactar el administrador del sistema.' : message;

   toast.error(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      // pauseOnHover: true,
      draggable: true
   });
}

export const showSuccessMessage = (res) => {
   const message = typeof res != 'string' ? 'Ha ocurrido un error. Favor de contactar el administrador del sistema.' : res;
   toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      // pauseOnHover: true,
      draggable: true
   });
}