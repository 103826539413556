/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { connect } from 'react-redux'

import PublicRoute from "../components/PublicRoute";
import PrivateRoute from "../components/PrivateRoute";
import { loginWithToken } from "store/actions/app";

const HomePage = lazy(() => import('./home/HomePage'));
const CasasPage = lazy(() => import('./mantenimientos/CasasPage'));
const GuardiasPage = lazy(() => import('./mantenimientos/GuardiasPage'));
const MensajesPage = lazy(() => import('./mantenimientos/MensajesPage'));
const CasasIndividualPage = lazy(() => import('./mantenimientos/CasasIndividualPage/index'));
const EventosPage = lazy(() => import('./mantenimientos/EventosPage'));
const ServiciosPublicosPage = lazy(() => import('./mantenimientos/ServiciosPublicosPage'));
const ProductosPage = lazy(() => import('./mantenimientos/ProductosPage'));
const ReportesPage = lazy(() => import('./reportes/index'));
const ConfigPage = lazy(() => import('./settings/index'));
const PagosPage = lazy(() => import('./pagos/index'));

const VisitasPage = lazy(() => import('./visitas/index'));

const LoginRoute = lazy(() => import('./login/index'));

const Routers = (props) => {
   const [isTryingToLogin, setIsTryingToLogin] = useState(true)

   const tryToLogin = async () => {
      const token = localStorage.getItem('@token')
      if (token) {
         await props.loginWithToken(token)
      }
      setIsTryingToLogin(false)
   }
   useEffect(() => {
      tryToLogin()
   }, [])

   if (isTryingToLogin) {
      return <Dimmer active inverted className='padding-0'>
         <Loader inverted>Cargando</Loader>
      </Dimmer>
   }
   return (

      <React.Fragment>
         <BrowserRouter>
            <Suspense fallback={
               <Segment className='width-100 height-100 padding-0 border-none margin-0 border-radius-0'>
                  <Dimmer active inverted className='padding-0'>
                     <Loader inverted>Cargando página</Loader>
                  </Dimmer>
               </Segment>
            }>
               <Switch>
                  <PublicRoute path="/login" component={LoginRoute} exact={true} />
                  <PrivateRoute path="/casas" component={CasasPage} exact={true} />
                  <PrivateRoute path="/casas/:id" component={CasasIndividualPage} exact={true} />
                  <PrivateRoute path="/guardias" component={GuardiasPage} exact={true} />
                  <PrivateRoute path="/mensajes" component={MensajesPage} exact={true} />
                  <PrivateRoute path="/eventos" component={EventosPage} exact={true} />
                  <PrivateRoute path="/visitas" component={VisitasPage} exact={true} />
                  <PrivateRoute path="/servicios_publicos" component={ServiciosPublicosPage} exact={true} />
                  <PrivateRoute path="/cuotas" component={ProductosPage} exact={true} />
                  <PrivateRoute path="/reportes" component={ReportesPage} exact={true} />
                  <PrivateRoute path="/configuracion" component={ConfigPage} exact={true} />
                  <PrivateRoute path="/pagos" component={PagosPage} exact={true} />
                  <PrivateRoute path="/" component={HomePage} exact={true} />
               </Switch>

            </Suspense>
         </BrowserRouter>
         {/* <NotificationMessage/>
      <ConfirmAction/>
      <InputModal/> */}
      </React.Fragment>
   )
}

const mapDispatchToProps = (dispatch) => ({
   loginWithToken: (parmas) => dispatch(loginWithToken(parmas)),
});

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Routers);

