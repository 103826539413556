import { Decimal } from "decimal.js";
import CDate from "utils/date";

export const formatColumn = (format, value) => {

   const type = typeof format === 'string' ? format : format.type;
   const decimals = typeof format === 'string' ? 2 : format.decimals;

   const transform = function (org, n, x, s, c) {
      const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
         num = org.toFixed(Math.max(0, ~~n));

      return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
   };
   switch (type) {
      case 'currency':
         return !isNaN(value) ? (
            `$${transform(parseFloat(value), decimals, 3, ',', '.')}`
         ) : value;
      case 'number':
         return !isNaN(value) ? (
            `${transform(parseFloat(value), decimals, 3, ',', '.')}`
         ) : value;
      case 'percentage':
         return !isNaN(value) ? (
            `${transform(parseFloat(value), decimals, 3, ',', '.')}%`
         ) : value;
      case 'date':
         if (!value) {
            return '';
         }
         return CDate(value).format("DD MMMM YYYY");
      case 'datemonth':
         if (!value) {
            return '';
         }
         return capitalize(CDate(value).format("MMMM YYYY"));
      case 'datetime':
         if (!value) {
            return '';
         }
         return CDate(value).format("DD MMMM YYYY, HH:mm");
      case 'time':
         if (!value) {
            return '';
         }
         return CDate(`2021-04-04 ${value}`).format("hh:mm A");
      case 'timeonly':
         if (!value) {
            return '';
         }
         return CDate(value).format("hh:mm A");
      default:
         return value
   }
};

export const convertToArrayObject = (array) => {
   return array.reduce((current, item) => {
      const id = item._id || item.id;
      current[id] = item;
      return current
   }, {})
};

/**
* Sort an array of objects by date using a custom property. It can be ascending or descending
* If dates are equal tries to compare ID
* @param {String} property - The property that will be used to sort the array of objects.
* @param {Boolean} desc - The order of the sort.
* @return {Function} Sort function with the custom property
* @example
* sortByDate('requisition_date')
* @example
* sortByDate('requisition_date', true)
*/
export const sortByDate = (property, desc = false) => {
   return (a, b) => {
      const compareId = (alternative) => {
         if (a.id != null && b.id != null) return a.id > b.id;
         else
            switch (alternative) {
               case 'after':
                  return CDate(a[property]).isAfter(b[property]);
               case 'before':
                  return CDate(a[property]).isBefore(b[property]);

               default:
                  break;
            }
      };

      const isSame = CDate(a[property]).isSame(b[property]);
      const isAfter = isSame
        ? compareId("after")
        : CDate(a[property]).isAfter(b[property]);
      const isBefore = isSame
        ? compareId("before")
        : CDate(a[property]).isBefore(b[property]);

      if (desc ? isAfter : isBefore) {
         return -1;
      } else {
         return 1;
      }
   };
};

export const replaceAll = (text = '', search, replacement) => {
   //for (var x in obj) {
   if (text == null || typeof text !== 'string')
      return '';
   text = text.replace(new RegExp(search, 'g'), replacement);

   return text;
};

/**
 * Converts an object to an array. If filterFunc is provided it will use it to filter the result array
 * @param {Object} obj - The object that will be convert to an array.
 * @param {Function} filterFunc - The function to filter the result array.
 * @return {Array} - The result array.
 * @example
 * convertObjectToArray({
 *    '0': {
 *    'name': 'Julio'
 *    },
 *    '1': {
 *    'name': 'Andre'
 *    }
 * })
 * @example
 * convertObjectToArray({
 *    '0': {
 *    'name': 'Julio'
 *    },
 *    '1': {
 *    'name': 'Andre'
 *    }
 * }, (item) => item.name === 'Julio' )
 */
export const convertObjectToArray = (obj, filterFunc) => {
   if (obj === null || obj === undefined)
      return [];
   if (filterFunc) {
      return Object.keys(obj).map(key => obj[key]).filter(filterFunc)
   } else {
      return Object.keys(obj).map(key => obj[key])
   }

};


export const getObjectProp = function (object, stringProp) {
   let s = stringProp;
   let o = object;
   s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
   s = s.replace(/^\./, '');           // strip a leading dot
   var a = s.split('.');
   for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
         o = o[k];
      } else {
         return;
      }
   }
   return o;
}

export const crypt = (salt, text) => {
   const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
   const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
   const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

   return text
      .split("")
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join("");
};

export const capitalize = (s) => {
   if (typeof s !== 'string') return ''
   return s.charAt(0).toUpperCase() + s.slice(1)
}

/** Calculate the total of an array of items using an especific property
 * @param {Array<Object>} flatListItems The flat list of items that would be groupped by the property
 * @param {String} property The property that will be used to group items
 * @return {Object} Key-value pairs with items groupped where key will be the property value
 */
export const calculateSimpleTotal = (flatListItems, property) => {
   return flatListItems.reduce((acum, item) => {
      const value = item[property] ?? 0
      return acum.plus(value)
   }, new Decimal(0))
}