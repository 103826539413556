const initialState = {
}
export default (state = initialState, action) => {
  const { type, payload, response } = action;
  switch (type) {
    case 'LOGIN': {
      return {
        ...state,
        user: payload,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: null,
      };
    }

    case 'UPDATE_VILLAGE': {
        return {
          ...state,
          user: {
            ...state.user,
            village: response
          }
        }
    }

    default:
      return state;
  }
};
