import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import HeaderRouter from '../components/HeaderRouter';
import { connect } from 'react-redux';

const PrivateRoute = ({
    isAuthenticated = true,
    component: Component,
    ...rest
}) => (
    <Route {...rest} render={(props) => (
        isAuthenticated ? (
            <div>
                <HeaderRouter history={props.history} />
                <Component {...props} />
            </div>
        ) : (
                <Redirect to="/login" />
            )
    )} />
);

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.app.user
});

export default connect(mapStateToProps)(PrivateRoute);

